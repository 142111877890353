import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { ChatBubbleLeftIcon, CheckIcon, ClockIcon, CodeBracketIcon, DocumentTextIcon, PencilIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

const WrapperComponent = ({ children, id, isNextProject, isInProgress, isCompleted, projectPath }) => (
  <Choose>
    <When condition={isInProgress || isCompleted || isNextProject}>
      <Link
        className={twMerge(
          'flex flex-row w-full p-3 sm:rounded-lg shadow bg-white hover:bg-gray-100 hover:cursor-pointer',
          isNextProject ? 'border-2 border-green-600 hover:bg-green-100' : 'border-2 border-transparent'
        )}
        to={`/students/${projectPath || 'projects'}/${id}`}
      >
        {children}
      </Link>
    </When>

    <Otherwise>
      <div className='flex flex-row w-full p-3 sm:rounded-lg shadow bg-gray-100 hover:cursor-disabled'>
        {children}
      </div>
    </Otherwise>
  </Choose>
)

const Project = ({ id, name, playground, isCompleted, isInProgress, isNextProject, projectPath }) => {
  return (
    <WrapperComponent
      id={id}
      isCompleted={isCompleted}
      isInProgress={isInProgress}
      isNextProject={isNextProject}
      projectPath={projectPath}
    >
      <Choose>
        <When condition={playground === 'CHAT'}>
          <ChatBubbleLeftIcon className='w-6 h-6 mr-3' />
        </When>

        <When condition={playground === 'CODE'}>
          <CodeBracketIcon className='w-6 h-6 mr-3' />
        </When>

        <When condition={playground === 'COMPLETION'}>
          <PencilIcon className='w-6 h-6 mr-3' />
        </When>

        <When condition={playground === 'DOC'}>
          <DocumentTextIcon className='w-6 h-6 mr-3' />
        </When>

        <Otherwise>
          <QuestionMarkCircleIcon className='w-6 h-6 mr-3' />
        </Otherwise>
      </Choose>

      <div className='w-full text-left'>
        <p className='text-lg font-semibold'>{name}</p>

        <Choose>
          <When condition={isInProgress}>
            <p className='text-sm text-gray-500 inline-block'>In progress</p>
            <ClockIcon className='h-4 w-4 inline-block ml-1' />
          </When>

          <When condition={isCompleted}>
            <p className='text-sm text-gray-500 inline-block'>Completed</p>
            <CheckIcon className='h-4 w-4 inline-block ml-1' />
          </When>

          <Otherwise>
            <p className='text-sm text-gray-500'>Not started</p>
          </Otherwise>
        </Choose>
      </div>
    </WrapperComponent>
  )
}

export default Project
