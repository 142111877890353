import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import TextInput from '@components/TextInput'
import Button from '@components/Button'
import Modal from '@components/Modal'
import Label from '@components/Label'
import LinkButton from '@components/LinkButton'
import SecureMarkdown from '@components/SecureMarkdown'
import TextEditor from '@components/TextEditor'

import BlockCard from './BlockCard'

const UPDATE_FREE_RESPONSE_BLOCK_MUTATION = gql`
  mutation updateFreeResponseBlock($input: UpdateFreeResponseBlockInput!) {
    updateFreeResponseBlock(input: $input) {
      freeResponse {
        id
        question
        correctAnswers {
          id
          answer
        }
      }
      errors {
        message
      }
    }
  }
`

const FreeResponseBlock = ({ id, question, correctAnswers, showDeleteModal, refetch, dragHandleProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      question,
      correctAnswers
    }
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'correctAnswers'
  })

  const { mutate: updateQuestionBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_FREE_RESPONSE_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => {
      refetch()
      setIsModalOpen(false)
    }
  })

  const onSubmit = ({ question, correctAnswers }) => {
    updateQuestionBlock({ question, correctAnswers })
  }

  return (
    <>
      <BlockCard
        title='Free response'
        icon={<QuestionMarkCircleIcon className='h-5 w-5' />}
        onEdit={setIsModalOpen}
        onDelete={showDeleteModal}
        dragHandleProps={dragHandleProps}
      >
        <p className='px-5 py-3 text-lg'>
          <Choose>
            <When condition={question}>
              <SecureMarkdown content={question} />
            </When>

            <Otherwise>
              <em>You haven't set a question just yet</em>
            </Otherwise>
          </Choose>
        </p>
      </BlockCard>

      <Modal
        size='md'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col px-5 py-5'>
          <h3 className='font-heading text-2xl font-bold mb-3'>Edit free response</h3>

          <Label labelFor='question' title='Question' />
          <Controller
            name='question'
            control={control}
            render={({ field }) => (
              <TextEditor
                imagesEnabled
                id='question'
                {...field}
              />
            )}
          />

          <Label className='mt-5'>Correct answers</Label>

          <For each='option' index='index' of={fields}>
            <div className='w-full flex flex-row items-center mb-3'>
              <TextInput
                aria-label='Correct answers'
                className='w-full'
                placeholder='Write your correct answer'
                {...register(`correctAnswers.${index}.answer`)}
              />

              <button
                aria-label='Remove correct answer'
                type='button'
                onClick={() => remove(index)}
                className='ml-1 hover:bg-gray-200 p-2 rounded-md'
              >
                <TrashIcon className='h-5 w-5' />
              </button>
            </div>
          </For>

          <LinkButton
            className='w-fit'
            type='button'
            onClick={() => append({ answer: '' })}
          >
            Add a correct answer
          </LinkButton>

          <Button
            className='ml-auto'
            type='submit'
            disabled={isLoading}
            label={isLoading ? 'Saving...' : 'Save'}
          />
        </form>
      </Modal>
    </>
  )
}

export default FreeResponseBlock
