import { twMerge } from 'tailwind-merge'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'

import Image from '@components/Image'

const removeMarkdownFormatting = text => {
  if (!text) return ''

  return text.replace(/[*_~$#|`]/g, '')
}

const TutorCard = ({ name, description, imageUrl }) => {
  const formattedDescription = removeMarkdownFormatting(description)

  return (
    <div className={twMerge('p-0 grow h-fit')}>
      <Choose>
        <When condition={imageUrl}>
          <Image
            className='object-cover w-full h-[200px] md:rounded-lg rounded-none'
            src={imageUrl}
            placeholder={<div className='md:rounded-lg rounded-none h-[200px] w-full bg-gray-200 animate-pulse border shadow' />}
          />
        </When>

        <Otherwise>
          <div className='flex justify-center items-center md:rounded-lg rounded-none h-[200px] w-full bg-blue-100'>
            <ChatBubbleLeftRightIcon className='w-12 h-12 text-blue-400' />
          </div>
        </Otherwise>
      </Choose>

      <div className='pt-3 sm:px-0 px-3'>
        <h2 className='text-lg font-semibold leading-none whitespace-nowrap overflow-hidden overflow-ellipsis'>{name}</h2>

        <If condition={formattedDescription}>
          <p className='mt-1 text-gray-500 leading-none whitespace-nowrap overflow-hidden overflow-ellipsis'>
            {formattedDescription}
          </p>
        </If>
      </div>
    </div>
  )
}

export default TutorCard
