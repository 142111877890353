import React, { useRef } from 'react'
import { PhotoIcon } from '@heroicons/react/24/outline'

const FileInput = props => {
  const fileInputRef = useRef()

  return (
    <>
      <button
        type='button'
        htmlFor='upload-button'
        className='flex items-center rounded-full hover:bg-gray-200 p-1 mr-1'
        onClick={() => fileInputRef.current.click()}
      >

        <PhotoIcon
          data-tutorial='vision-step'
          className='h-6 w-6 group-disabled:text-gray-400'
        />
      </button>

      <input
        ref={fileInputRef}
        id='upload-button'
        type='file'
        // Accepts image files, but also allows plain text.
        // This allows Android 14 devices to select from the file picker and use the camera.
        // Remove text/plain as an option once this Android 14 bug is fixed: https://issuetracker.google.com/issues/317289301
        accept='.jpg,.jpeg,.png,.heic,.heif,.webp,text/plain'
        multiple
        className='hidden'
        {...props}
      />
    </>
  )
}

export default FileInput
