import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import SeriesCard from '../../components/SeriesCard'

import Project from './Project'

const SERIES_QUERY = gql`
  query series($id: ID!) {
    node(id: $id) {
      ... on Series {
        id
        name
        description
        educatorName
        organizationName
        coverImage {
          url
        }
        educatorProjects {
          id
          name
          playground
          mySubmission {
            id
            status
          }
        }
      }
    }
  }
`

const SeriesDetails = ({ pageHeading, projectPath }) => {
  const { id } = useParams()

  const { isLoading, data: { node: series = {} } = {} } = useQuery({
    queryKey: ['series', id],
    queryFn: async () => request(SERIES_QUERY, { id })
  })

  if (isLoading) return

  const nextProject = series.educatorProjects?.find(project => project.mySubmission?.status !== 'COMPLETED')
  const nextProjectId = nextProject?.id

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label={pageHeading || 'My courses'} to='/educators/courses' />
        <BreadCrumbs.Text label={series.name} />
      </BreadCrumbs>

      <div className='sm:m-5'>
        <div className='flex flex-row gap-5 flex-wrap lg:flex-nowrap'>
          <SeriesCard
            id={series.id}
            className='basis-full lg:basis-2/5 shrink-0'
            name={series.name}
            educatorName={series.educatorName}
            organizationName={series.organizationName}
            description={series.description}
            coverImageUrl={series.coverImage?.url}
            projectCount={series.educatorProjects?.length}
          />

          <div className='flex flex-col grow-0 basis-full gap-3'>
            <h3 className='text-xl font-semibold'>Lessons</h3>

            <For index='index' each='project' of={series.educatorProjects}>
              <Project
                key={project.id}
                id={project.id}
                name={project.name}
                playground={project.playground}
                isCompleted={project.mySubmission?.status === 'COMPLETED'}
                isInProgress={project.mySubmission?.status === 'IN_PROGRESS'}
                isNextProject={project.id === nextProjectId}
                projectPath={projectPath}
              />
            </For>

            <If condition={!nextProject}>
              <p className='font-semibold text-lg'>You've completed this course 🥳</p>
            </If>
          </div>
        </div>
      </div>
    </>
  )
}

export default SeriesDetails
