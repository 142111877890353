import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useForm } from 'react-hook-form'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import {
  BoldIcon,
  ItalicIcon,
  CodeBracketIcon,
  NumberedListIcon,
  ListBulletIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  TableCellsIcon,
  PhotoIcon,
  H1Icon,
  H2Icon,
  H3Icon
} from '@heroicons/react/24/outline'

import Tooltip from '@components/Tooltip'
import Modal from '@components/Modal'
import TextInput from '@components/TextInput'
import Button from '@components/Button'

const ToolButton = ({ id, label, icon, disabled, className, onClick }) => (
  <>
    <button
      id={id}
      type='button'
      onClick={onClick}
      disabled={disabled}
      className={twMerge('px-2 py-1 rounded-md hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400', className)}
      aria-label={label}
    >
      {icon}
    </button>

    <Tooltip anchorSelect={`#${id}`}>
      {label}
    </Tooltip>
  </>
)

const Divider = () => <div className='border-l border-gray-300 h-6 m-1' />

const LatexText = ({ math }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef.current) {
      katex.render(math, containerRef.current, {
        throwOnError: false
      })
    }
  }, [math])

  return <span ref={containerRef} />
}

const ImageModal = ({ editor, isOpen, onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onTouched' })

  const submit = ({ url }) => {
    editor.chain().focus().setImage({ src: url }).run()
    onClose()
  }

  return (
    <Modal
      size='sm'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className='flex flex-col' onSubmit={handleSubmit(submit)}>
        <TextInput
          className='mt-3'
          label='Image URL'
          {...register('url', { required: 'Image URL is required' })}
        />

        <If condition={errors.url}>
          <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.url.message}</p>
        </If>

        <Button
          onClick={handleSubmit(submit)}
          className='ml-auto mt-3'
          label='Save'
        />
      </form>
    </Modal>
  )
}

const ToolBar = ({ editor, imagesEnabled }) => {
  if (!editor) {
    return null
  }

  const [isImageModalOpen, setIsImageModalOpen] = useState(false)

  const isTableFocused = editor.isActive('table')

  return (
    <div className='bg-gray-100 border-x border-t border-gray-300 rounded-t-md'>
      <div className='flex flex-wrap p-1'>
        <ToolButton
          id='heading-1-button'
          label='Heading 1'
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 1 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H1Icon className='size-4' />}
        />

        <ToolButton
          id='heading-2-button'
          label='Heading 2'
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H2Icon className='size-4' />}
        />

        <ToolButton
          id='heading-3-button'
          label='Heading 3'
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 3 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H3Icon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='bold-button'
          label='Bold'
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<BoldIcon className='size-4' />}
        />

        <ToolButton
          id='italic-button'
          label='Italic'
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'bold' : ''}
          icon={<ItalicIcon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='unordered-list-button'
          label='Bullet List'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'bold' : ''}
          icon={<ListBulletIcon className='size-4' />}
        />

        <ToolButton
          id='numbered-list-button'
          label='Numbered List'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'bold' : ''}
          icon={<NumberedListIcon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='code-button'
          label='Code'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'bold' : ''}
          icon={<CodeBracketIcon className='size-4' />}
        />

        <ToolButton
          id='table-button'
          label='Table'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
          className={editor.isActive('codeBlock') ? 'bold' : ''}
          icon={<TableCellsIcon className='size-4' />}
        />

        <If condition={imagesEnabled}>
          <ToolButton
            id='image-button'
            label='Image'
            disabled={isTableFocused}
            onClick={() => setIsImageModalOpen(!isImageModalOpen)}
            icon={<PhotoIcon className='size-4' />}
          />
        </If>

        <ToolButton
          id='math-button'
          label='Math'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleMath(String.raw`\placeholder{}`).run()} // eslint-disable-line
          className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'bold' : ''}
          icon={<LatexText math='x^2' />}
        />

        <div className='flex ml-auto'>
          <ToolButton
            id='undo-button'
            label='Undo'
            onClick={() => editor.chain().focus().undo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .undo()
                .run()
            }
            icon={<ArrowUturnLeftIcon className='size-4' />}
          />

          <ToolButton
            id='redo-button'
            label='Redo'
            onClick={() => editor.chain().focus().redo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .redo()
                .run()
            }
            icon={<ArrowUturnRightIcon className='size-4' />}
          />
        </div>
      </div>

      <If condition={imagesEnabled}>
        <ImageModal
          editor={editor}
          isOpen={isImageModalOpen}
          onClose={() => setIsImageModalOpen(false)}
        />
      </If>
    </div>
  )
}

export default ToolBar
