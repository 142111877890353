import { useState, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMutation, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { useChannel } from '@contexts/actionCable'
import { request } from '@helpers/graphql'
import { useAnalytics } from '@contexts/analytics'
import Conversation from '@components/Conversation'

import UpgradeBanner from '../../educators/components/UpgradeBanner'

const CREATE_CHAT_MESSAGE_MUTATION = gql`
  mutation sendEducatorProjectChatMessage($input: SendEducatorProjectChatMessageInput!) {
    sendEducatorProjectChatMessage(input: $input) {
      chatMessages(perPage: 10) {
        nodes {
          id
          status
          from
          text
          createdAt
          attachments {
            url
            filename
          }
        }
      }
    }
  }
`

const CHAT_MESSAGES_QUERY = gql`
  query educatorProjectSubmission($id: ID!, $page: Int!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        chatMessages(page: $page, perPage: 10) {
          pagesCount
          nodesCount
          nodes {
            id
            status
            from
            text
            createdAt
            attachments {
              url
              filename
            }
          }
        }
      }
    }
  }
`

const TRANSCRIBE_AUDIO_MUTATION = gql`
  mutation transcribeAudio($input: TranscribeProjectAudioInput!) {
    transcribeProjectAudio(input: $input) {
      success
    }
  }
`

const UPLOAD_ATTACHMENTS_MUTATION = gql`
  mutation uploadEducatorProjectAttachments($input: UploadEducatorProjectAttachmentsInput!) {
    uploadEducatorProjectAttachments(input: $input) {
      attachments {
        id
        url
        filename
      }
      errors {
        message
      }
    }
  }
`

const TEXT_TO_SPEECH_MUTATION = gql`
  mutation educatorProjectTextToSpeech($input: EducatorProjectTextToSpeechInput!) {
    educatorProjectTextToSpeech(input: $input) {
      success
    }
  }
`

const ChatPlayground = ({ submissionId }) => {
  const { subscribe, unsubscribe } = useChannel()
  const queryClient = useQueryClient()
  const { setValue, reset } = useFormContext()
  const idleToolStatus = { type: 'tool_status', tool: undefined, status: 'idle' }
  const [toolStatus, setToolStatus] = useState(idleToolStatus)
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const { track } = useAnalytics()

  const {
    data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status
  } = useInfiniteQuery({
    queryKey: ['chatMessages', submissionId],
    queryFn: async ({ pageParam = 1 }) => request(CHAT_MESSAGES_QUERY, { id: submissionId, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.node.chatMessages.pagesCount > pages.length) {
        return pages.length + 1
      }

      return false
    },
    select: (data) => ({
      pages: [...data.pages].reverse(),
      pageParams: [...data.pageParams].reverse()
    })
  })

  const {
    mutate: sendChatMessage,
    isLoading: isSending
  } = useMutation({
    mutationFn: async variables => request(CREATE_CHAT_MESSAGE_MUTATION, variables),
    onSuccess: newData => {
      queryClient.setQueryData(['chatMessages', submissionId], oldData => {
        const oldNodes = oldData.pages[0].node.chatMessages.nodes
        const newNodes = newData.sendEducatorProjectChatMessage.chatMessages.nodes
        const mergedNodes = [...newNodes]

        // Add old nodes that are not in the new nodes, exclusing temp
        oldNodes.forEach(node => {
          const foundNode = mergedNodes.find(n => n.id === node.id)

          if (!foundNode && !node.temp) {
            mergedNodes.push(node)
          }
        })

        return {
          ...oldData,
          pages: [
            {
              ...oldData.pages[0],
              node: {
                ...oldData.pages[0].node,
                chatMessages: {
                  nodes: mergedNodes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                }
              }
            },
            ...oldData.pages.slice(1)
          ]
        }
      })
    }
  })

  const submitMessage = data => {
    sendChatMessage({ input: { educatorProjectSubmissionId: submissionId, text: data.text, attachmentIds: data.attachmentIds } })
    setIsAwaitingResponse(true)
    reset()

    queryClient.setQueryData(['chatMessages', submissionId], oldData => ({
      ...oldData,
      pages: [
        {
          ...oldData.pages[0],
          node: {
            ...oldData.pages[0].node,
            chatMessages: {
              ...oldData.pages[0].node.chatMessages,
              nodes: [
                {
                  ...data,
                  from: 'USER',
                  createdAt: new Date().toISOString(),
                  temp: true
                },
                ...oldData.pages[0].node.chatMessages.nodes
              ]
            }
          }
        },
        ...oldData.pages.slice(1)
      ]
    }))
  }

  const { mutate: transcribeAudio, isLoading: isTranscribing } = useMutation({
    mutationFn: async (variables) =>
      request(TRANSCRIBE_AUDIO_MUTATION, variables)
  })

  const { mutateAsync: uploadAttachments, isLoading: isUploading } = useMutation({
    mutationFn: async data => request(UPLOAD_ATTACHMENTS_MUTATION, { input: data })
  })

  const handleUploadAttachments = async files => {
    const data = await uploadAttachments({ attachments: files })

    return data.uploadEducatorProjectAttachments
  }

  const { mutateAsync: textToSpeechMutation } = useMutation({
    mutationFn: async (variables) => request(TEXT_TO_SPEECH_MUTATION, variables)
  })

  const audioPlayerRef = useRef(null)

  const [currentAudioMessageId, setCurrentAudioMessageId] = useState(null)

  const handlePlayPauseAudio = (messageId) => {
    setCurrentAudioMessageId(messageId)
  }

  useEffect(() => {
    subscribe({
      channel: 'EducatorProjectSubmissionChannel',
      educator_project_submission_id: submissionId
    }, {
      received: data => {
        if (data.type === 'message') {
          queryClient.setQueryData(['chatMessages', submissionId], oldData => {
            const oldNodes = oldData.pages[0].node.chatMessages.nodes
            const newNodes = oldNodes.map(node =>
              node.id === data.id
                ? { ...node, text: data.text }
                : node
            )

            if (!newNodes.find(n => n.id === data.id)) {
              newNodes.unshift(data)
            }

            return {
              ...oldData,
              pages: [
                {
                  ...oldData.pages[0],
                  node: {
                    ...oldData.pages[0].node,
                    chatMessages: { nodes: newNodes }
                  }
                },
                ...oldData.pages.slice(1)
              ]
            }
          })
        }

        if (data.type === 'tool_status') {
          setIsAwaitingResponse(false)
          setToolStatus(data)
        }

        if (data.type === 'transcription') {
          setValue('text', data.text)
        }

        if (data.type === 'text_to_speech') {
          audioPlayerRef.current.src = data.audio_url
          audioPlayerRef.current.load()
          handlePlayPauseAudio(data.message_id)
        }
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Conversation
      chatEnabled
      ChatDisabledComponent={UpgradeBanner}
      transcribeAudio={file => transcribeAudio({ input: { file, educatorProjectSubmissionId: submissionId } })}
      isTranscribing={isTranscribing}
      sendMessage={submitMessage}
      uploadAttachments={handleUploadAttachments}
      isUploading={isUploading}
      isSending={isSending}
    >
      <Choose>
        <When condition={status === 'loading'}>
          <Conversation.LoadingState />
        </When>

        <Otherwise>
          {/* <If condition={data?.pages?.[0]?.node?.messages?.pagesCount === 0}>
            <Conversation.EmptyState name={name} />
          </If> */}

          <Conversation.LoadMore
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
          />

          {/* Audio player for text-to-speech */}
          <audio ref={audioPlayerRef} />

          <For each='page' of={data.pages} index='index'>
            <div key={`page-${index}`} className='flex flex-col-reverse'>
              <For each='message' of={page.node.chatMessages.nodes}>
                <Conversation.Message
                  key={message.id}
                  id={message.id}
                  status={message.status}
                  text={message.text}
                  from={message.from}
                  sourceName={message.sourceName}
                  sourceLink={message.sourceLink}
                  createdAt={message.createdAt}
                  attachments={message.attachments}
                  audioPlayerRef={audioPlayerRef}
                  currentAudioMessageId={currentAudioMessageId}
                  textToSpeechMutation={textToSpeechMutation}
                  trackAudioPlayed={() =>
                    track('Educator Project Audio Played', {
                      submissionId,
                      text: message.text
                    })}
                />
              </For>
            </div>
          </For>

          <If condition={!['idle', 'streaming'].includes(toolStatus.status)}>
            <Conversation.ToolLoadingState tool={toolStatus.tool} status={toolStatus.status} />
          </If>

          <If condition={isAwaitingResponse}>
            <Conversation.MessageLoadingState />
          </If>

          {/* <Conversation.SuggestionList>
            <For each='suggestion' of={suggestions}>
              <Conversation.Suggestion
                key={suggestion.text}
                text={suggestion.text}
                onClick={() => {
                  submitMessage({ text: suggestion.text })

                  track('Tutor Chat Suggestion Accepted', {
                    chatId,
                    text: suggestion.text
                  })
                }}
              />
            </For>
          </Conversation.SuggestionList> */}
        </Otherwise>
      </Choose>
    </Conversation>
  )
}

export default ChatPlayground
