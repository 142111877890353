import Card from '@components/Card'

const NoResults = () => (
  <Card className='w-auto flex flex-col items-center justify-center p-10 m-5'>
    <img src='/swingset.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No insights available just yet</h4>
    <p>Check back after you've ended your sessions for this classroom.</p>
  </Card>
)

export default NoResults
