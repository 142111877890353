import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'
import { LightBulbIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'
import SecureMarkdown from '@components/SecureMarkdown'
import TextEditor from '@components/TextEditor'
import Label from '@components/Label'

import BlockCard from './BlockCard'

const UPDATE_REFLECTION_BLOCK_MUTATION = gql`
  mutation updateReflectionBlock($input: UpdateReflectionBlockInput!) {
    updateReflectionBlock(input: $input) {
      reflection {
        question
      }
      errors {
        message
      }
    }
  }
`

const ReflectionBlock = ({ id, question, showDeleteModal, refetch, dragHandleProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { handleSubmit, control } = useForm({ defaultValues: { question } })

  const { mutate: updateReflectionBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_REFLECTION_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => {
      refetch()
      setIsModalOpen(false)
    }
  })

  const onSubmit = ({ question }) => {
    updateReflectionBlock({ question })
  }

  return (
    <>
      <BlockCard
        title='Reflection'
        icon={<LightBulbIcon className='h-5 w-5' />}
        onEdit={setIsModalOpen}
        onDelete={showDeleteModal}
        dragHandleProps={dragHandleProps}
      >
        <p className='px-5 py-3 text-lg'>
          <Choose>
            <When condition={question}>
              <SecureMarkdown content={question} />
            </When>

            <Otherwise>
              <em>You haven't set a question just yet</em>
            </Otherwise>
          </Choose>
        </p>
      </BlockCard>

      <Modal
        size='md'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col px-5 py-5'>
          <h3 className='font-heading text-2xl font-bold mb-3'>Edit reflection</h3>

          <Label labelFor='question' title='Question' />
          <Controller
            name='question'
            control={control}
            render={({ field }) => (
              <TextEditor
                imagesEnabled
                id='question'
                {...field}
              />
            )}
          />

          <Button
            className='ml-auto mt-3'
            type='submit'
            disabled={isLoading}
            label={isLoading ? 'Saving...' : 'Save'}
          />
        </form>
      </Modal>
    </>
  )
}

export default ReflectionBlock
