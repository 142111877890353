import { forwardRef, useEffect } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { Markdown } from 'tiptap-markdown'

import { TableBubbleMenu, MathExtension } from '@components/TextEditor'

import KeyboardHandler from './KeyboardHandler'
import ToolBar from './ToolBar'

const extensions = [
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3]
    },
    horizontalRule: false,
    blockquote: false,
    hardBreak: false
  }),
  Markdown.configure({
    html: false, // Allow HTML input/output
    tightLists: false, // No <p> inside <li> in markdown output
    tightListClass: 'tight', // Add class to <ul> allowing you to remove <p> margins when tight
    bulletListMarker: '*', // <li> prefix in markdown output
    linkify: true, // Create links from "https://..." text
    breaks: true, // New lines (\n) in markdown input are converted to <br>
    transformPastedText: true, // Allow to paste markdown text in the editor
    transformCopiedText: true // Copied text is transformed to markdown
  }),
  Table.configure({
    resizable: false,
    allowTableNodeSelection: false
  }),
  TableRow,
  TableHeader,
  TableCell,
  MathExtension,
  KeyboardHandler
]

const TextEditor = forwardRef(({ value, onChange, disabled, toolbarEnabled = true, onError }, ref) => {
  const editor = useEditor({
    extensions,
    content: value,
    editable: !disabled,
    editorProps: {
      attributes: {
        class: 'p-3 text-editor block w-full min-h-[40px] max-h-[400px] overflow-scroll p-0 border-none ring-0 focus:ring-0 focus:border-none focus-within:outline-none shadow-none text-lg sm:leading-6 resize-none'
      },
      transformPastedHTML: html => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const table = doc.querySelector('table')

        if (table) {
          // Check for complex HTML not supported by GFM
          const complexElements = table.querySelectorAll('img, a, strong, em, code, pre, blockquote, ul, ol, li')
          if (complexElements.length > 0) {
            // Complex HTML found, return an empty string to prevent pasting
            if (onError) onError('Complex formatting within tables is not supported')

            return ''
          }

          // Check for multi-column rows
          const colspanCells = table.querySelectorAll('td[colspan], th[colspan]')
          if (colspanCells.length > 0) {
            // Multi-column rows found, return an empty string to prevent pasting
            if (onError) onError('Multi-column rows are not supported')

            return ''
          }

          const headers = table.querySelectorAll('th')

          if (headers.length === 0) {
            const firstRow = table.querySelector('tr')

            firstRow.querySelectorAll('td').forEach((cell) => {
              const header = document.createElement('th')
              header.textContent = cell.textContent
              firstRow.replaceChild(header, cell)
            })
          }

          // Remove any nested tables
          const nestedTables = table.querySelectorAll('table')
          nestedTables.forEach(nestedTable => nestedTable.remove())

          // Remove any block elements inside table cells
          const blockElements = table.querySelectorAll('div, p')
          blockElements.forEach(element => {
            const textContent = element.textContent
            element.parentNode.replaceChild(document.createTextNode(textContent), element)
          })

          // Return only the table HTML
          return table.outerHTML
        }

        // If no table is found, return the original HTML
        return html
      }
    },
    onUpdate: ({ editor }) => {
      if (onChange) {
        onChange(editor.storage.markdown.getMarkdown())
      }
    }
  })

  useEffect(() => {
    // If value is set externally OR value is blank, update the editor content.
    if (!editor.isFocused || !value) {
      editor.commands.setContent(value)
    }
  }, [value])

  return (
    <div className='w-full'>
      <ToolBar className={toolbarEnabled ? 'flex' : 'hidden'} editor={editor} />
      <TableBubbleMenu editor={editor} />
      <EditorContent ref={ref} editor={editor} />
    </div>
  )
})

export default TextEditor
