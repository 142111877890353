import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import {
  BoldIcon,
  ItalicIcon,
  CodeBracketIcon,
  NumberedListIcon,
  ListBulletIcon,
  TableCellsIcon,
  H1Icon,
  H2Icon,
  H3Icon
} from '@heroicons/react/24/outline'

import Tooltip from '@components/Tooltip'

import keyboardReturnIcon from './keyboard-return.svg'
import keyboardShiftIcon from './keyboard-shift.svg'

const ToolButton = ({ id, label, icon, disabled, className, onClick }) => (
  <>
    <button
      id={id}
      type='button'
      onClick={onClick}
      disabled={disabled}
      className={twMerge('px-2 py-0.5 rounded-md hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400', className)}
      aria-label={label}
    >
      {icon}
    </button>

    <Tooltip anchorSelect={`#${id}`} delayShow={500}>
      {label}
    </Tooltip>
  </>
)

const Divider = () => <div className='border-l border-gray-300 h-6 m-1' />

const LatexText = ({ math }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef.current) {
      katex.render(math, containerRef.current, {
        throwOnError: false
      })
    }
  }, [math])

  return <span ref={containerRef} />
}

const ToolBar = ({ editor, className }) => {
  if (!editor) {
    return null
  }

  const isTableFocused = editor.isActive('table')

  return (
    <div className={twMerge('bg-gray-100 rounded-t', className)}>
      <div className='w-full flex flex-wrap items-center p-1'>
        <ToolButton
          id='heading-1-button'
          label='Heading 1'
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 1 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H1Icon className='size-4' />}
        />

        <ToolButton
          id='heading-2-button'
          label='Heading 2'
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H2Icon className='size-4' />}
        />

        <ToolButton
          id='heading-3-button'
          label='Heading 3'
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 3 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H3Icon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='bold-button'
          label='Bold'
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<BoldIcon className='size-4' />}
        />

        <ToolButton
          id='italic-button'
          label='Italic'
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'bold' : ''}
          icon={<ItalicIcon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='unordered-list-button'
          label='Bullet List'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'bold' : ''}
          icon={<ListBulletIcon className='size-4' />}
        />

        <ToolButton
          id='numbered-list-button'
          label='Numbered List'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'bold' : ''}
          icon={<NumberedListIcon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='code-button'
          label='Code'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'bold' : ''}
          icon={<CodeBracketIcon className='size-4' />}
        />

        <ToolButton
          id='table-button'
          label='Table'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
          className={editor.isActive('codeBlock') ? 'bold' : ''}
          icon={<TableCellsIcon className='size-4' />}
        />

        <ToolButton
          id='math-button'
          label='Math'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleMath(String.raw`\placeholder{}`).run()} // eslint-disable-line
          className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'bold' : ''}
          icon={<LatexText math='x^2' />}
        />

        <p className='flex items-center ml-auto text-xs pr-2 text-gray-500'>
          Use&nbsp;
          <img className='size-4 border border-gray-500 rounded' alt='Shift' src={keyboardShiftIcon} />
          &nbsp;+&nbsp;
          <img className='size-4 border border-gray-500 rounded' alt='Return' src={keyboardReturnIcon} />
          &nbsp;for new line
        </p>

      </div>
    </div>
  )
}

export default ToolBar
