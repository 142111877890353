import { useRef } from 'react'

import AutoScroll from '@components/AutoScroll'
import { useCurrentUser } from '@contexts/currentUser'

import MessageForm from './MessageForm'

const Conversation = ({
  children,
  sendMessage,
  uploadAttachments,
  isUploading,
  transcribeAudio,
  isTranscribing,
  noticeMessage = "AI responses aren't always factually accurate.",
  chatEnabled = true,
  ChatDisabledComponent
}) => {
  const {
    user: { organization: { features = [] } = {} }
  } = useCurrentUser()

  const mathsInputEnabled = features.includes('maths_input')
  const visionEnabled = features.includes('vision')

  return (
    <div className='h-full w-full overflow-auto bg-white relative flex flex-col'>
      <div className='absolute top-0 right-0 left-0 h-10 z-10 bg-gradient-to-b from-white to-transparent' />

      {/* Height is 100% minus message input */}
      <AutoScroll className='h-[calc(100%-60px)] flex flex-col overflow-scroll pt-10 md:p-5 px-3'>
        {children}
      </AutoScroll>

      <Choose>
        <When condition={chatEnabled}>
          <MessageForm
            sendMessage={sendMessage}
            uploadAttachments={uploadAttachments}
            isUploading={isUploading}
            noticeMessage={noticeMessage}
            mathsInputEnabled={mathsInputEnabled}
            visionEnabled={visionEnabled}
            transcribeAudio={transcribeAudio}
            isTranscribing={isTranscribing}
          />
        </When>

        <Otherwise>
          <ChatDisabledComponent />
        </Otherwise>
      </Choose>
    </div>
  )
}

export default Conversation
